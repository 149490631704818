import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a href="http://www.wizards.com/dnd/images/xph_gallery/80444.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><a href="http://www.wizards.com/dnd/images/xph_gallery/80443.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><a id="psionic-races"></a>Psionics
Races
    </h2>
    <a id="dromites"></a><h3>DROMITES</h3>
    <ul>
      <li><a href="http://www.wizards.com/dnd/images/xph_gallery/80489.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
            "border": "0px solid",
            "width": "73px",
            "height": "75px"
          }} /></a>
        <p className="initial">+2 Charisma, -2 Strength, -2 Wisdom</p>
      </li>
      <li>
        <p>Monstrous Humanoid: Dromites are not subject to spells or
effects that
affect humanoids only, such as <a href="spellsC.html#charm-person" style={{
            "color": "rgb(87, 158, 182)"
          }}>charm person</a> or <a href="spellsDtoE.html#dominate-person" style={{
            "color": "rgb(87, 158, 182)"
          }}>dominate person</a>.</p>
      </li>
      <li>
        <p>Small: As a Small creature, a dromite gains a +1 size
bonus to Armor
Class, a +1 size bonus on attack rolls, and a +4 size bonus on <a href="skillsAll.html#hide" style={{
            "color": "rgb(87, 158, 182)"
          }}>Hide</a>
checks, but it uses smaller weapons than humans use, and its lifting
and carrying limits are three-quarters of those of a Medium character.</p>
      </li>
      <li>
        <p>Dromite base land speed is 20 feet.</p>
      </li>
      <li>
        <a id="dromite-chitin"></a><p>Chitin: A dromite&#8217;s skin is hardened, almost like an
exoskeleton, and
grants the character a +3 natural armor bonus to AC and one of the
following kinds of <a href="monsterTypes.html#resistance-to-energy" style={{
            "color": "rgb(87, 158, 182)"
          }}>resistance to energy</a>: cold 5,
electricity 5, fire 5,
or sonic 5. The player chooses what type of energy resistance is gained
when the character is created. (This choice also dictates which caste
the dromite belongs to.) This natural energy resistance stacks with any
future energy resistance gained through other effects.</p>
      </li>
      <li>
        <p>Naturally Psionic: Dromites gain 1 bonus power point at
1st level. This
benefit does not grant them the ability to manifest powers unless they
gain that ability through another source, such as levels in a psionic
class.</p>
      </li>
      <li>
        <p>Psi-Like Ability: 1/day-<a href="psionicPowersDtoF.html#energy-ray" style={{
            "color": "rgb(87, 158, 182)"
          }}>energy ray</a>. A dromite always
deals
the kind of
energy damage that its chitin has resistance to (for example, a dromite
who has resistance to cold 5 deals cold damage with its energy ray).
Manifester level is equal to 1/2 Hit Dice (minimum 1st). The save DC is
Charisma-based.</p>
      </li>
      <li>
        <p>Scent: Its antennae give a dromite the scent ability. A
dromite can
detect opponents by scent within 30 feet. If the opponent is upwind,
the range increases to 60 feet; if downwind, it drops to 15 feet.
Strong scents, such as smoke or rotting garbage, can be detected at
twice the ranges noted above. Overpowering scents, such as skunk musk
or troglodyte stench, can be detected at triple normal range. When a
dromite detects a scent, the exact location of the source is not
revealed - only its presence somewhere within range. The dromite can
take a
move action to note the direction of the scent. Whenever the dromite
comes within 5 feet of the source, the dromite pinpoints the source&#8217;s
location.</p>
      </li>
      <li>
        <p>Blind-Fight: Its antennae also give a dromite <a href="featsAll.html#blind-fighting" style={{
            "color": "rgb(87, 158, 182)"
          }}>Blind-Fight</a>
as a bonus feat.</p>
      </li>
      <li>
        <a id="dromite-compound-eyes"></a><p>Compound Eyes: This feature of its anatomy gives a dromite
a +2 racial bonus on <a href="skillsAll.html#spot" style={{
            "color": "rgb(87, 158, 182)"
          }}>Spot</a> checks.</p>
      </li>
      <li>
        <p>Automatic Languages: Common. Bonus Languages: Dwarven,
Gnome, Goblin, Terran.</p>
      </li>
      <li>
        <p>Favored Class: <a href="psionicWilder.html" style={{
            "color": "rgb(87, 158, 182)"
          }}>Wilder</a>.</p>
      </li>
      <li>
        <p>Level Adjustment: +1.</p>
      </li>
    </ul>
    <a id="duergar"></a><h3>DUERGAR</h3>
    <ul>
      <li>
        <p className="initial"><a href="http://www.wizards.com/dnd/images/xph_gallery/80490.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
              "border": "0px solid",
              "width": "73px",
              "height": "75px"
            }} /></a>+2
Constitution, -4 Charisma</p>
      </li>
      <li>
        <p>Medium: As Medium creatures, duergar have no special
bonuses or penalties due to their size.</p>
      </li>
      <li>
        <p>Duergar base land speed is 20 feet. However, gray dwarves
can move at
this speed even when wearing medium or heavy armor or when carrying a
medium or heavy load (unlike other creatures, whose speed is reduced in
such situations).</p>
      </li>
      <li>
        <p>Darkvision out to 120 feet.</p>
      </li>
      <li>
        <p>Immunity to paralysis, phantasms, and poison.</p>
      </li>
      <li>
        <p>+2 racial bonus on saves against spells and spell-like
effects.</p>
      </li>
      <li>
        <a id="duergar-stability"></a><p>Stability: Duergar are exceptionally stable on their feet.
A duergar
receives a +4 bonus on ability checks made to resist being <a href="specialAttacks.html#bull-rush" style={{
            "color": "rgb(87, 158, 182)"
          }}>bull
rushed </a>or <a href="specialAttacks.html#trip" style={{
            "color": "rgb(87, 158, 182)"
          }}>tripped</a> when standing on the
ground (but not when climbing, flying,
riding or otherwise not standing firmly on the ground).</p>
      </li>
      <li>
        <a id="duergar-stonecunning"></a><p>Stonecunning: This ability grants a duergar a +2 racial
bonus on <a href="skillsAll.html#search" style={{
            "color": "rgb(87, 158, 182)"
          }}>Search</a>
checks to notice unusual stonework, such as sliding walls, stonework,
traps, new construction (even when built to match the old), unsafe
stone surfaces, shaky stone ceilings, and the like. Something that
isn&#8217;t stone but that is disguised as stone also counts as unusual
stonework.
A gray dwarf who merely comes within 10 feet of unusual stonework can
make a Search check as if he were actively searching, and a duergar can
use the Search skill to find stonework traps as a rogue can. A duergar
can also intuit depth, sensing his approximate depth underground as
naturally as a human can sense which way is up. Duergar have a sixth
sense about stonework, an innate ability that they get plenty of
opportunity to practice and hone in their underground homes.</p>
      </li>
      <li>
        <p>Psi-Like Abilities: 1/day-<a href="psionicPowersDtoF.html#expansion" style={{
            "color": "rgb(87, 158, 182)"
          }}>expansion</a>, <a href="psionicPowersGtoP.html#invisibility-psionic" style={{
            "color": "rgb(87, 158, 182)"
          }}>invisibility</a>. These
abilities
affect only the duergar and whatever he carries. Manifester level is
equal to Hit Dice (minimum 3rd).</p>
      </li>
      <li>
        <p>Naturally Psionic: Duergar gain 3 bonus power points at
1st level. This
benefit does not grant them the ability to manifest powers unless they
gain that ability through another source, such as levels in a psionic
class.</p>
      </li>
      <li>
        <p>+4 dodge bonus to Armor Class against creatures of the
giant type (such as ogres, trolls, and hill giants).</p>
      </li>
      <li>
        <a id="duergar-light-sensitivity"></a><p>Light Sensitivity (Ex): Duergar are dazzled in sunlight or
within the radius of a <a href="spellsDtoE.html#daylight" style={{
            "color": "rgb(87, 158, 182)"
          }}>daylight</a> spell.</p>
      </li>
      <li>
        <p>Duergar have a +4 racial bonus on <a href="skillsAll.html#move-silently" style={{
            "color": "rgb(87, 158, 182)"
          }}>Move
Silently</a> checks and a +1 racial
bonus on <a href="skillsAll.html#listen" style={{
            "color": "rgb(87, 158, 182)"
          }}>Listen</a> and <a href="skillsAll.html#spot" style={{
            "color": "rgb(87, 158, 182)"
          }}>Spot </a>checks.
They have a +2 racial bonus on <a href="skillsAll.html#appraise" style={{
            "color": "rgb(87, 158, 182)"
          }}>Appraise</a> and <a href="skillsAll.html#craft" style={{
            "color": "rgb(87, 158, 182)"
          }}>Craft</a>
checks that are related to stone or metal.</p>
      </li>
      <li>
        <p>Automatic Languages: Common, Dwarven, Undercommon. Bonus
Languages: Draconic, Giant, Goblin, Orc, Terran.</p>
      </li>
      <li>
        <p>Favored Class: <a href="fighter.html" style={{
            "color": "rgb(71, 158, 182)"
          }}>Fighter</a>.</p>
      </li>
      <li>Level Adjustment: +1.</li>
    </ul>
    <a id="elans"></a><h3>ELANS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li>
        <p className="initial"><a href="http://www.wizards.com/dnd/images/xph_gallery/80491.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
              "border": "0px solid",
              "width": "73px",
              "height": "75px"
            }} /></a>-2 Charisma</p>
      </li>
      <li>
        <p>Aberration: Elans are not subject to spells or effects
that affect humanoids only, such as <a href="spellsC.html#charm-person" style={{
            "color": "rgb(87, 158, 182)"
          }}>charm person</a> or <a href="spellsDtoE.html#dominate-person" style={{
            "color": "rgb(87, 158, 182)"
          }}>dominate person</a>.</p>
      </li>
      <li>
        <p>Medium: As Medium creatures, elans have no special bonuses
or penalties due to their size.</p>
      </li>
      <li>
        <p>Elan base land speed is 30 feet.</p>
      </li>
      <li>
        <p>Elans (unlike most aberrations) do not have darkvision.</p>
      </li>
      <li>
        <p>Naturally Psionic: Elans gain 2 bonus power points at 1st
level. This
benefit does not grant them the ability to manifest powers unless they
gain that ability through another source, such as levels in a psionic
class.</p>
      </li>
      <li>
        <a id="elan-resistance"></a><p>Resistance (Su): Elans can use psionic energy to increase
their
resistance to various forms of attack. As an immediate action, an elan
can spend 1 power point to gain a +4 racial bonus on saving throws
until the beginning of her next action.</p>
      </li>
      <li>
        <a id="elan-resilience"></a><p>Resilience (Su): When an elan takes damage, she can spend
power points
to reduce its severity. As an immediate action, she can reduce the
damage she is about to take by 2 hit points for every 1 power point she
spends.</p>
      </li>
      <li>
        <a id="elan-repletion"></a><p>Repletion (Su): An elan can sustain her body without need
of food or
water. If she spends 1 power point, an elan does not need to eat or
drink for 24 hours.</p>
      </li>
      <li>
        <p>Automatic Language: Common. Bonus Languages: Any (other
than secret
languages, such as Druidic). Elans&#8217; past lives expose them to wide
ranges of language.</p>
      </li>
      <li>
        <p>Favored Class: <a href="psionicPsion.html" style={{
            "color": "rgb(87, 158, 182)"
          }}>Psion</a>.</p>
      </li>
      <li>
        <p>Level Adjustment: +0.</p>
      </li>
    </ul>
    <a id="half-giants"></a><h3>HALF-GIANTS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li>
        <p className="initial"><a href="http://www.wizards.com/dnd/images/xph_gallery/80496.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
              "border": "0px solid",
              "width": "73px",
              "height": "75px"
            }} /></a>+2
Strength, +2 Constitution, -2 Dexterity: Half-giants are tough and
strong, but not too nimble.</p>
      </li>
      <li>
        <p>Giant: Half-giants are not subject to spells or effects
that affect humanoids only, such as <a href="spellsC.html#charm-person" style={{
            "color": "rgb(87, 158, 182)"
          }}>charm person</a> or <a href="spellsDtoE.html#dominate-person" style={{
            "color": "rgb(87, 158, 182)"
          }}>dominate person</a>.</p>
      </li>
      <li>
        <p>Medium: As Medium creatures, half-giants have no special
bonuses or penalties due to their size.</p>
      </li>
      <li>
        <p>Half-giant base land speed is 30 feet.</p>
      </li>
      <li>
        <p>Low-Light Vision: A half-giant can see twice as far as a
human in
starlight, moonlight, torchlight, and similar conditions of poor
illumination. He retains the ability to distinguish color and detail
under these conditions.</p>
      </li>
      <li>
        <a id="half-giant-fire-acclimated"></a><p>Fire Acclimated: Half-giants have a +2 racial bonus on
saving throws
against all fire spells and effects. Half-giants are accustomed to
enduring high temperatures.</p>
      </li>
      <li>
        <a id="half-giant-powerful-build"></a><p>Powerful Build: The physical stature of half-giants lets
them function in many ways as if they were one size category larger.
Whenever a half-giant is subject to a size modifier or special size
modifier for an opposed check (such as during <a href="specialAttacks.html#grapple" style={{
            "color": "rgb(87, 158, 182)"
          }}>grapple</a>
checks, <a href="specialAttacks.html#bull-rush" style={{
            "color": "rgb(87, 158, 182)"
          }}>bull rush</a>
attempts, and <a href="specialAttacks.html#trip" style={{
            "color": "rgb(87, 158, 182)"
          }}>trip</a> attempts), the half-giant
is treated as one size
larger if doing so is advantageous to him.
A half-giant is also considered to be one size larger when determining
whether a creature&#8217;s special attacks based on size (such as improved
grab or swallow whole) can affect him. A half-giant can use weapons
designed for a creature one size larger without penalty. However, his
space and reach remain those of a creature of his actual size. The
benefits of this racial trait stack with the effects of powers,
abilities, and spells that change the subject&#8217;s size category.</p>
      </li>
      <li>
        <p>Naturally Psionic: Half-giants gain 2 bonus power points
at 1st level.
This benefit does not grant them the ability to manifest powers unless
they gain that ability through another source, such as levels in a
psionic class.</p>
      </li>
      <li>
        <p>Psi-Like Ability: 1/day-<a href="psionicPowersQtoW.html#stomp" style={{
            "color": "rgb(87, 158, 182)"
          }}>stomp</a>.
Manifester level is equal
to 1/2 Hit Dice (minimum 1st). The save DC is Charisma-based.</p>
      </li>
      <li>
        <p>Automatic Language: Common. Bonus Languages: Draconic,
Giant, Gnoll, Ignan.</p>
      </li>
      <li>
        <p>Favored Class: <a href="psionicPsychicWarrior.html" style={{
            "color": "rgb(87, 158, 182)"
          }}>Psychic warrior</a>.</p>
      </li>
      <li>
        <p>Level Adjustment: +1.</p>
      </li>
    </ul>
    <a id="maenads"></a><h3>MAENADS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li>
        <p className="initial"><a href="http://www.wizards.com/dnd/images/xph_gallery/80498.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
              "border": "0px solid",
              "width": "73px",
              "height": "75px"
            }} /></a>Medium: As
Medium creatures, maenads have no special bonuses or penalties due to
their size.</p>
      </li>
      <li>
        <p>Maenad base land speed is 30 feet.</p>
      </li>
      <li>
        <p>Naturally Psionic: Maenads gain 2 bonus power points at
1st level. This
benefit does not grant them the ability to manifest powers unless they
gain that ability through another source, such as levels in a psionic
class.</p>
      </li>
      <li>
        <p>Psi-Like Ability: 1/day-<a href="psionicPowersDtoF.html#energy-ray" style={{
            "color": "rgb(87, 158, 182)"
          }}>energy ray</a>. A maenad can deal
only
sonic damage
with this ability. It is accompanied by a tremendous scream of rage.
Manifester level is equal to 1/2 Hit Dice (minimum 1st). The save DC is
Charisma-based.</p>
      </li>
      <li>
        <a id="maenad-outburst"></a><p>Outburst (Ex): Once per day, for up to 4 rounds, a maenad
can subjugate
her mentality to gain a boost of raw physical power. When she does so,
she takes a -2 penalty to Intelligence and Wisdom but gains a +2 bonus
to Strength.</p>
      </li>
      <li>
        <p>Automatic Languages: Common, Maenad. Bonus Languages:
Aquan, Draconic,
Dwarven, Elven, Goblin. Maenads commonly know the languages of their
enemies and of their friends, as well as Draconic, the language
commonly found in ancient tomes of secret knowledge.</p>
      </li>
      <li>
        <p>Favored Class: Wilder.</p>
      </li>
      <li>
        <p>Level Adjustment: +0</p>
      </li>
    </ul>
    <a id="xephs"></a><h3>XEPHS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li>
        <p className="initial"><a href="http://www.wizards.com/dnd/images/xph_gallery/80512.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
              "border": "0px solid",
              "width": "73px",
              "height": "75px"
            }} /></a>+2
Dexterity, -2 Strength</p>
      </li>
      <li>
        <p>Medium: As Medium creatures, xephs have no special bonuses
or penalties due to their size.</p>
      </li>
      <li>
        <p>Xeph base land speed is 30 feet.</p>
      </li>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p>+1 racial bonus on saving throws against powers, spells,
and spell-like
effects. Xephs have an innate resistance to psionics and magic.</p>
      </li>
      <li>
        <p>Naturally Psionic: Xephs gain 1 bonus power point at 1st
level. This
benefit does not grant them the ability to manifest powers unless they
gain that ability through another source, such as levels in a psionic
class.</p>
      </li>
      <li>
        <a id="xeph-burst"></a><p>Burst (Su): Three times per day, a xeph can put on a burst
of speed to
increase her speed by 10 feet, plus 10 feet per four character levels
beyond 1st, to a maximum increase of 30 feet at 9th character level and
higher. These bursts of speed are considered a competence bonus to the
xeph&#8217;s base speed. A burst of speed lasts 3 rounds.</p>
      </li>
      <li>
        <p>Automatic Languages: Common, Xeph. Bonus Languages:
Draconic, Elven, Gnoll, Goblin, Halfling, Sylvan.</p>
      </li>
      <li>
        <p>Favored Class: Soulknife.</p>
      </li>
    </ul>
    <h3><a id="age-height-and-weight"></a>AGE, HEIGHT, AND WEIGHT</h3>
    <a id="table-random-starting-ages"></a><b>Table: Random Starting Ages</b>
    <table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "75px"
          }}>Race
          </th>
          <th style={{
            "width": "95px"
          }}>Adulthood
          </th>
          <th style={{
            "width": "85px"
          }}>Barbarian Rogue Sorcerer Soulknife
Wilder
          </th>
          <th style={{
            "width": "85px"
          }}>Bard Fighter Paladin Ranger Psychic
Warrior
          </th>
          <th style={{
            "width": "85px"
          }}>Cleric Druid Monk Psion Wizard
          </th>
        </tr>
        <tr className="odd-row">
          <td>Dromite
          </td>
          <td>13 years&nbsp;&nbsp; 
          </td>
          <td>+1d4
          </td>
          <td>+1d6
          </td>
          <td>+2d6
          </td>
        </tr>
        <tr>
          <td>Duergar
          </td>
          <td>40 years
          </td>
          <td>+3d6
          </td>
          <td>+5d6
          </td>
          <td>+7d6
          </td>
        </tr>
        <tr className="odd-row">
          <td>Elan
          </td>
          <td>20 years
          </td>
          <td>+2d4
          </td>
          <td>+2d6
          </td>
          <td>+3d6
          </td>
        </tr>
        <tr>
          <td>Half-giant
          </td>
          <td>30 years
          </td>
          <td>+3d6
          </td>
          <td>+2d6
          </td>
          <td>+4d6
          </td>
        </tr>
        <tr className="odd-row">
          <td>Maenad
          </td>
          <td>40 years
          </td>
          <td>+1d6
          </td>
          <td>+4d6
          </td>
          <td>+6d6
          </td>
        </tr>
        <tr>
          <td>Xeph
          </td>
          <td>18 years
          </td>
          <td>+1d4
          </td>
          <td>+2d4
          </td>
          <td>+2d6
          </td>
        </tr>
      </tbody>
    </table>
    <a id="table-aging-effects"></a><b>Table: Aging Effects</b>
    <p>{`| Race | Middle Age`}<sup>{`1`}</sup>{` | Old`}<sup>{`2`}</sup>{` |Venerable`}<sup>{`3`}</sup>{` | Maximum Age
| Dromite | 30 years | 55 years | 75 years | +2d20 years
| Duergar | 125 years | 188 years | 250 years | +2d% years
| Elan | 200 years | 400 years | 1,000 years | N/A
| Half-giant | 60 years | 80 years | 120 years | +4d% years
| Manead | 90 years | 150 years | 220 years | +2d% years
| Xeph | 45 years | 85 years | 120 years | +2d20 years
1 `}<span style={{
        "fontStyle": "italic"
      }}>{`-1to Str, Dex, and Con; +1 to Int, Wis, and Cha.`}</span>{`
2 `}<span style={{
        "fontStyle": "italic"
      }}>{`-2to Str, Dex, and Con; +1 to Int, Wis, and Cha.`}</span>{`
3 `}<span style={{
        "fontStyle": "italic"
      }}>{`-3to Str, Dex, and Con; +1 to Int, Wis, and Cha.`}</span></p>
    <b><a id="table-random-height-and-weaight"></a>Table: Random Height and Weight
    </b>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Race`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Base Height`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Height Modifier`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Base Weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Weight Modifier`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dromite`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2'8''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x1 lb.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duergar, male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3'9''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`110 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x(2d4) lb.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duergar, female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3'7''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x(2d4) lb.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Elan, male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4'10''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`120 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x(2d4) lb.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Elan, female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4'5''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`85 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x(2d4) lb.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Half-giant, male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6'4''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`220 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x(2d6) lb.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Half-giant, female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6'1''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`180 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x(2d6) lb.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Maenad, male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5'4''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x(2d4) lb.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Maenad, female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5'3''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`120 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x(2d4) lb.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Xeph, male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4'8''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x(2d4) lb.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Xeph, female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4'4''`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2d10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75 lb.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`x(2d4) lb.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      